import { Route, ScheduleElement, Waypoint } from "shared-types/RouteTypes";

/**
 * If the route leg has identical min and max speeds,
 * then the speed is unambiguous and we can use that
 *
 * @param {Waypoint} w
 *
 * @returns {number | undefined}
 */
const getSpeedFromWaypointMinMaxSpeed = (w: Waypoint) => {
  return w.leg?.speedMax === w.leg?.speedMin ? w.leg?.speedMax : undefined;
};

/**
 * If a route only has departure and arrival waypoints,
 * we attempt to use the speeds for each route leg to
 * fill in the rest of the waypoints
 *
 * @param {Route} route
 * @param {ScheduleElement[]} scheduleElements
 *
 * @returns {void}
 */
const fillInWaypoints = (route: Route) => {
  if (!route.schedules?.schedules?.[0]?.manual?.scheduleElements) {
    return;
  }
  const originalScheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;
  // get a speed defined on the last waypoint using speedMin and speedMax,
  const lastWaypointSpeed = getSpeedFromWaypointMinMaxSpeed(
    route.waypoints.waypoints[route.waypoints.waypoints.length - 1]
  );
  const newScheduleElements = [
    { ...originalScheduleElements[0] },
    // fill the space between with a schedule element for each waypoint
    ...route.waypoints.waypoints.slice(1, -1).map((w) => {
      const speed = getSpeedFromWaypointMinMaxSpeed(w);
      return {
        waypointId: w.id,
        // if the speed is defined unambiguously on the waypoint, use it
        ...(speed && { speed }),
      } as ScheduleElement;
    }),
    {
      ...originalScheduleElements[originalScheduleElements.length - 1],
      // if the speed is defined unambiguously on the last waypoint, use it
      ...(lastWaypointSpeed && { speed: lastWaypointSpeed }),
    },
  ];
  // replace the original elements with the new ones
  route.schedules.schedules[0].manual.scheduleElements = newScheduleElements;
};

export { fillInWaypoints };
